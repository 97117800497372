:root {
  --background-color: #f1f1f1;
  --ctaColor: #10a3b4;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 2rem;
  align-self: flex-start;
  font-family: 'Roboto Serif', serif;
  font-size: large;
}

/* h1:before {
  content:"\A";
  width:10px;
  height:10px;
  border-radius:50%;
  background: var(--ctaColor);
  display:inline-block;
  margin:0 10px;
} */

html,
body {
  background: var(--background-color);
}

main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: space-evenly;
  min-height: calc(100vh);
  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
}

section {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 25vw;
  max-width: 75vw;
  align-items: center;
  flex-grow: 1;
  padding: 1rem;
}

section:first-of-type {
  padding-top: 0;
  padding-bottom: 0;
}

a,
a:visited {
  color: black;
  text-decoration: none;
  box-shadow: 0 1px rgba(128, 128, 128, 0.5);
}

a:active,
a:hover,
a:focus {
  box-shadow: 0 2px var(--ctaColor);
  transition: all 0.3s ease-in-out;
}

div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

figure {
  margin: 0;
  border-radius: 3rem;
  display: block;
  position: relative;
}

img {
  display: flex;
  min-width: 100px;
  max-width: 150px;
  border-radius: 5px;
}

footer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5vh;
}

@media screen and (max-width: 700px) {
  section {
    padding: 0;
  }
  img {
    max-width: 100px
  }
  p {
    font-size: large;
  }
}

.welcome-wrapper {
  position: relative;
}

.spinner {
  position: absolute;
  display: flex;
  transform: scale(.2);
  left: -3.5rem;
}

.spinner svg
{
  width: 250px;
  position: absolute;
  animation-name: whirl;
  animation-iteration-count:infinite;
  animation-direction:alternate-reverse;
  animation-duration: 30s;
  transition: all ease-in-out;
}

.spinner rect {
  fill: transparent;
}

.spinner svg:nth-child(1) {
  animation-delay: 0ms;
}

.spinner svg:nth-child(2) {
  animation-delay: 100ms;
}

.spinner svg:nth-child(3) {
  animation-delay: 200ms;
}

.spinner svg:nth-child(4) {
  animation-delay: 300ms;
}

.spinner svg:nth-child(5) {
  animation-delay: 400ms;
}

.spinner svg:nth-child(6) {
  animation-delay: 500ms;
}

.spinner svg:nth-child(7) {
  animation-delay: 600ms;
}

.spinner svg:nth-child(8) {
  animation-delay: 700ms;
}

.spinner rect {
  fill: rgba(16, 164, 180, 0.2);
  stroke: var(--ctaColor);
  stroke-width: 5;
  opacity: .5;
  
}

@keyframes whirl {
  0% {
    transform: rotateY(0deg) rotateZ(0deg) rotateX(0deg);
  }
  25% {
    transform: rotateY(360deg) rotateZ(180deg) rotateX(180deg);
  }
  75% {
    transform: rotateY(-360deg) rotateZ(-180deg) rotateX(-180deg);
  }
  100% {
    transform: rotateY(0deg) rotateZ(0deg) rotateX(0deg);
  }
}